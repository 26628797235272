<script setup lang="ts">
import empty_file from '~/assets/image/empty/empty_file@2x.png'
import { PersonalApi } from '~/net/apis/personal_api'
import { useDayjs } from '#dayjs'

// exchangeCDkeyRecord
const onClickLeft = () => history.back()
const key = ref('')
const list: ref<ExchangeCDKeyRecord[]> = ref()
const startTime = ref()
const endTime = ref()
const dayjs = useDayjs()
const today = dayjs().format('YYYY-MM-DD')
interface Option {
  text: string
  // 在这个例子中，我们不需要在接口中定义isActive，因为它是由activeIndex来管理的
}
const options: Option[] = [{
  text: '全部'
}, {
  text: '今日'
}, {
  text: '近7天'
}, {
  text: '近30天'
}]
const activeIndex = ref(0) // 跟踪哪个选项是活动的
function choose(index: number) {
  // 更新活动的索引（如果需要的话）
  activeIndex.value = index
  switch (index) {
    case 1:
      startTime.value = today
      endTime.value = today
      break
    case 2:
      startTime.value = dayjs().subtract(6, 'day').format('YYYY-MM-DD')
      endTime.value = today
      break
    case 3:
      startTime.value = dayjs().subtract(29, 'day').format('YYYY-MM-DD')
      endTime.value = today
      break
    default:
      startTime.value = null
      endTime.value = null
      break
  }
}
async function exchangeCDkeyRecord() {
  const { data } = await PersonalApi.exchangeCDkeyRecord({ startTime: startTime.value, endTime: endTime.value })
  list.value = data
}
exchangeCDkeyRecord()

async function exchangeCDkey() {
  const { data } = await PersonalApi.exchangeCDkey({ cdKey: key.value })
  exchangeCDkeyRecord()
}

function transferListToString(infoList: array) {
  let tipString = ''
  for (let i = 0; i < infoList.length; i++) {
    const info = infoList[i]
    const skuName = info.skuName || ''
    const skuNumber = info.skuNumber || 0
    if (info.skuNumber !== 0) {
      tipString += `${skuName}*${skuNumber}`
    }
    if (i < (infoList.length - 1)) {
      tipString += '、'
    }
  }
  return tipString
}
function dateFormat(data: string) {
  if (!data) {
    return ''
  }
  return dayjs(data).format('MM月dd日-HH:mm')
}
// 监听请求参数是否发生变化
watch(
  activeIndex,
  (newValue, oldValue) => {
    console.log('>>>>>>>.', newValue, oldValue)
    if (newValue !== oldValue)
      exchangeCDkeyRecord()
  }
)
</script>

<template>
  <div h-screen>
    <van-nav-bar title="兑换码" left-arrow @click-left="onClickLeft" />
    <div h-48 w-full flex flex-col items-center bg-292f49>
      <div mt-10 h-14 w-80 rounded-md>
        <van-field v-model="key" placeholder="请输入CDKey" />
      </div>
      <span ct-ff0000 line-height-0 text-xs v-if="key">请输入正确的CDKey!</span>
      <div mt-4 h-10 w-40 rounded-8 text-center leading-10 class="btn" :class="{ disabled: !key }" @click="exchangeCDkey">兑换</div>
    </div>
    <div>
      <div m-4 flex flex-row text-center ct-ffffff>
        <div
          v-for="(option, index) in options"
          :key="index"
          mr-2
          flex-1
          border-1
          rounded-2
          :class="{ active: index === activeIndex }"
          @click="choose(index)"
        >
          {{ option.text }}
        </div>
      </div>
      <div>
        <div v-if="list && list.length > 0">
          <!-- 排序 -->
          <div v-for="(model, index) in list" :key="index" class="gift-item">
            <div class="item-content">
              <div class="item-row">
                <span class="cd-key">{{ model.cdKey }}</span>
                <span class="exchange-time">{{ dateFormat(model.exchangeTime) }}</span>
              </div>
              <div class="sku-list">
                {{ transferListToString(model.skuList) }}
              </div>
            </div>
            <div class="divider" />
          </div>
        </div>
        <div v-else h-xl flex flex-col items-center justify-center px-4>
          <img h-33 w-50 :src="empty_file" />
          <div mt-3 text-xs ct-ffffff>主人，暂时没有相关内容</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
:deep(.van-field__control) {
  color: #e8e8e8;
}
:deep(.van-field__control::placeholder) {
  color: #e8e8e8;
}
:deep(.van-cell) {
  font-size: 16px;
  background: #111425;
  color: #e8e8e8;
  border-radius: 30px;
  border-bottom: 1px solid #41466040;
}
:deep(.van-cell:after) {
  border: none;
}
:deep(.van-cell-group--inset) {
  border-radius: unset;
}
:deep(.van-field__label) {
  color: #e8e8e8;
}
:deep(.van-cell__value) {
  color: #5c5e69;
}
.btn{
  color: #2a2016;
  background: linear-gradient(to bottom, #FFDCA2 50%, #FFC87A 100%);
  &.disabled{
    pointer-events: none;
    cursor: not-allowed;
    background: #95806b;
    color: #2a2016;
  }
}
.active {
  background: #272d45;
  border: none;
}

.gift-item {
  margin-bottom: 10px; /* 适当调整样式 */
}
.item-content {
  height: 50px; /* 使用合适的高度 */
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-row {
  display: flex;
  align-items: center;
}
.cd-key {
  color: white;
  font-size: 12px;
}
.exchange-time {
  color: #D4D4D4;
  font-size: 10px;
}
.sku-list {
  color: white;
  font-size: 10px;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #FFFFFF;
}
</style>
